import consumer from '../consumer'
import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
import { visit } from '../../components/utils'

consumer.subscriptions.create('Admin::NotificationsChannel', {
  received(data) {
    if (!window.UserWebNotificationsEnabled) return

    if (window.App.browserNotificationsAllowed) {
      let notification = new Notification(data.title, {
        body: data.message,
        icon: window.WebNotificationsIconURL
      })

      if (data.link !== null) {
        notification.onclick = (e) => {
          visit(data.link)
        }
      }

      setTimeout(notification.close.bind(notification), 3000)
    } else {
      const notyf = new Notyf({ duration: 3000 })

      if (data.type == 'success') {
        if (data.link !== null) {
          notyf.success(`<a href="${data.link}">${data.message}</a>`)
        } else {
          notyf.success(data.message)
        }
      } else {
        if (data.link !== null) {
          notyf.error(`<a href="${data.link}">${data.message}</a>`)
        } else {
          notyf.error(data.message)
        }
      }
    }
  }
})
