import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js/pure'
import tippy from 'tippy.js'
import { i18n } from 'i18n/index.js'
import { visit } from '../components/utils'

export default class extends Controller {
  static targets = [
    'submitWrapper',
    'submit',
    'success',
    'successMessage',
    'error',
    'errorMessage',
    'terms',
    'card',
    // "pots",
    // "potsMessage",
    'form'
  ]

  static values = {
    apiKey: String,
    clientSecret: String
  }

  initialize() {
    // Disable Stripe pinging its servers on each page load
    // For users privacy mainly
    loadStripe.setLoadParameters({ advancedFraudSignals: false })
  }

  connect() {
    this.setupStripe()

    this.submitTooltip = tippy(this.submitWrapperTarget, {
      content: i18n.t('orders.show.please_accept_sale_terms')
    })

    if (this._termsChecked()) {
      this.submitTooltip.disable()
    }
  }

  async setupStripe() {
    this.stripe = await loadStripe(this.apiKeyValue, {
      locale: 'fr'
    })

    this.clientSecret = this.clientSecretValue

    let elements = this.stripe.elements()

    let style = {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#32325d'
        }
      },

      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    }

    this.card = elements.create('card', { hidePostalCode: true, style: style })
    this.card.mount('#card-element')

    this.card.on('ready', (event) => {
      this.handleStripeEvent({ empty: true })
    })

    this.card.on('change', (event) => {
      this.handleStripeEvent(event)
    })
  }

  handleStripeEvent({ empty }) {
    // Disable the pay button if there are no card details entered
    if (!this._termsChecked() || empty) {
      this.submitTooltip.enable()
      this.submitTarget.disabled = true
      this.submitTarget.classList.add('not-allowed')
    } else {
      this.submitTooltip.disable()
      this.submitTarget.disabled = false
      this.submitTarget.classList.remove('not-allowed')
    }

    if (event.error) {
      this.errorMessageTarget.textContent = event.error.message
      this.errorTarget.classList.remove('hidden')
    } else {
      this.errorTarget.classList.add('hidden')
    }
  }

  updateTerms() {
    if (this._termsChecked()) {
      this.submitTooltip.disable()
      this.submitTarget.disabled = false
      this.submitTarget.classList.remove('not-allowed')
      // this.potsTarget.classList.remove("hidden");
      // this.potsMessageTarget.classList.add("hidden");
    } else {
      this.submitTooltip.enable()
      this.submitTarget.disabled = true
      this.submitTarget.classList.add('not-allowed')
      // this.potsTarget.classList.add("hidden");
      // this.potsMessageTarget.classList.remove("hidden");
    }

    // this.autosave();
  }

  _termsChecked() {
    return this.termsTarget.value === '1' || this.termsTarget.value === 'true'
  }

  payWithCard(event) {
    event.preventDefault()

    this.loading(true)

    this.stripe
      .confirmCardPayment(this.clientSecret, {
        payment_method: {
          card: this.card
        }
      })
      .then((result) => {
        if (result.error) {
          // Show error to your customer
          this.showError(result.error.message)
        } else {
          // The payment succeeded!
          this.orderComplete(result.paymentIntent.id)
        }
      })
  }

  orderComplete(paymentIntentId) {
    this.loading(false)

    this.successTarget.classList.remove('hidden')
    this.cardTarget.classList.add('hidden')

    this.submitTarget.disabled = true
    this.submitTarget.classList.add('not-allowed')
    // this.potsTarget.classList.add("hidden");
    // this.potsMessageTarget.classList.remove("hidden");

    setTimeout(() => {
      visit(window.location + '/confirmation?payment_id=' + paymentIntentId)
    }, 1000)
  }

  showError(errorMsgText) {
    this.loading(false)

    this.errorMessageTarget.textContent = errorMsgText
    this.errorTarget.classList.remove('hidden')
  }

  loading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      this.submitTarget.disabled = true
      this.submitTarget.classList.add('not-allowed')
    } else {
      this.submitTarget.disabled = false
      this.submitTarget.classList.remove('not-allowed')
    }

    for (const svg of this.submitTarget.querySelectorAll('svg')) {
      svg.classList.toggle('hidden')
    }
  }

  autosave() {
    const formdata = new FormData(this.formTarget)

    fetch(`${this.formTarget.getAttribute('action')}.json`, {
      method: 'PUT',
      credentials: 'include',
      headers: {
        'X-CSRF-Token': document.querySelector(`meta[name="csrf-token"]`).content
      },
      body: formdata
    })
  }

  destroy() {
    this.submitTooltip.destroy()
  }
}
