import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "products",
    "topSpectacles",
    "topSpectacleVenues",
    "topSpectacleCities",
    "topSpectacleCategories",
    "topConcerts",
    "topConcertVenues",
    "topConcertCities",
    "topConcertCategories",
    "topExhibitions",
    "topExhibitionVenues",
    "topExhibitionCities",
    "topExhibitionCategories",
  ];

  connect() {
    fetch(this.data.get("url"))
      .then((response) => response.json())
      .then((data) => {
        this.displayList(data.products, this.productsTarget, "coffrets-cadeaux");
        this.displayList(data.top_spectacles, this.topSpectaclesTarget, "evenements");
        this.displayList(data.top_spectacle_venues, this.topSpectacleVenuesTarget, "lieux");
        this.displayListWithExtra(
          data.top_spectacle_cities,
          this.topSpectacleCitiesTarget,
          "evenements",
          "Spectacles à",
          "?show_type=spectacle&city="
        );
        this.displayListWithExtra(
          data.top_spectacle_categories,
          this.topSpectacleCategoriesTarget,
          "evenements",
          "",
          "?show_type=spectacle&categories="
        );
        this.displayList(data.top_concerts, this.topConcertsTarget, "evenements");
        this.displayList(data.top_concert_venues, this.topConcertVenuesTarget, "lieux");
        this.displayListWithExtra(
          data.top_concert_cities,
          this.topConcertCitiesTarget,
          "evenements",
          "Concerts à",
          "?show_type=concert&city="
        );
        this.displayListWithExtra(
          data.top_concert_categories,
          this.topConcertCategoriesTarget,
          "evenements",
          "",
          "?show_type=concert&categories="
        );
        this.displayList(data.top_exhibitions, this.topExhibitionsTarget, "evenements");
        this.displayList(data.top_exhibition_venues, this.topExhibitionVenuesTarget, "lieux");
        this.displayListWithExtra(
          data.top_exhibition_cities,
          this.topExhibitionCitiesTarget,
          "evenements",
          "Musées et Monuments à",
          "?show_type=exhibition&city="
        );
        this.displayListWithExtra(
          data.top_exhibition_categories,
          this.topExhibitionCategoriesTarget,
          "evenements",
          "",
          "?show_type=exhibition&categories="
        );
      });
  }

  displayList(data, target, segment) {
    if (data !== undefined)
      target.innerHTML = data
        .map((item) => `<li><a href="https://culturinthecity.com/${segment}/${item.slug}">${item.name}</a></li>`)
        .join("");
  }

  displayListWithExtra(data, target, segment, sentence, extra = "") {
    if (data !== undefined)
      target.innerHTML = data
        .map(
          (item) =>
            `<li><a href="https://culturinthecity.com/${segment}${extra}${item.name}">${sentence} ${item.name}</a></li>`
        )
        .join("");
  }
}
