import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["block", "radio", "name", "description"];

  connect() {
    this.setSelection();
  }

  setSelection() {
    for (const [index, radio] of this.radioTargets.entries()) {
      if (radio.checked) {
        this.blockTargets[index].classList.add("bg-brand-blue-50", "border-brand-blue-200", "z-10");
        this.nameTargets[index].classList.add("text-brand-blue-900");
        this.descriptionTargets[index].classList.add("text-brand-blue-700");
        this.blockTargets[index].classList.remove("border-gray-200");
        this.nameTargets[index].classList.remove("text-gray-900");
        this.descriptionTargets[index].classList.remove("text-gray-500");
      } else {
        this.blockTargets[index].classList.add("border-gray-200");
        this.nameTargets[index].classList.add("text-gray-900");
        this.descriptionTargets[index].classList.add("text-gray-500");
        this.blockTargets[index].classList.remove("bg-brand-blue-50", "border-brand-blue-200", "z-10");
        this.nameTargets[index].classList.remove("text-brand-blue-900");
        this.descriptionTargets[index].classList.remove("text-brand-blue-700");
      }
    }
  }

  setSelected(event) {
    const index = event.target.getAttribute("data-index");
    if (index !== null) {
      this.radioTargets[index].checked = true;
      this.setSelection();
    }
  }
}
