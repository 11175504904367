import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["short", "extended", "showMore", "showLess"];

  connect() {
    if (this.hasExtendedTarget) {
      this.shortTarget.classList.remove("hidden");
      this.extendedTarget.classList.add("hidden");
      this.showMoreTarget.classList.remove("hidden");
      this.showLessTarget.classList.add("hidden");
    }
  }

  toggleText(event) {
    event.preventDefault();
    this.shortTarget.classList.toggle("hidden");
    this.extendedTarget.classList.toggle("hidden");
    this.showMoreTarget.classList.toggle("hidden");
    this.showLessTarget.classList.toggle("hidden");
  }
}
