import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";

export default class extends Controller {
  static targets = [];
  static values = { text: String };

  connect() {
    tippy(this.element, {
      content: this.textValue,
    });
  }
}
