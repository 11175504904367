import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["filterBlocks"];

  connect() {
    const hash = window.location.hash;

    if (hash.indexOf("#filter-") !== undefined) {
      const title = `${hash.replace("#filter-", "")}-title`;
      const menu = `${hash.replace("#filter-", "")}-menu`;

      let menuElement = document.getElementById(menu);
      if (menuElement !== null) {
        menuElement.classList.toggle("hidden");
        menuElement.classList.toggle("flex");
      }

      let titleElement = document.getElementById(title);
      if (titleElement !== null) {
        titleElement.scrollIntoView({
          block: "center",
        });
      }
    }
  }

  toggleMenu(event) {
    event.preventDefault();

    let target = event.target.id;

    if (target.length === 0) {
      target = event.target.parentNode.id;
    }

    target = target.replace("title", "menu");
    document.getElementById(target).classList.toggle("hidden");
    document.getElementById(target).classList.toggle("flex");
  }

  toggleFilters(event) {
    event.preventDefault();
    this.filterBlocksTarget.classList.toggle("hidden");
  }
}
