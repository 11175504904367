import { Controller } from "@hotwired/stimulus"
import mapboxgl from "mapbox-gl";

export default class extends Controller {
  static values = { token: String, stores: Array };

  initialize() {
    mapboxgl.accessToken = this.tokenValue;

    this.markers = [];
  }

  connect() {
    this.map = new mapboxgl.Map({
      container: this.element.id,
      interactive: true,
      zoom: 6,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [2.3619834, 48.8654865],
    });

    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
      })
    );

    for (const store of this.storesValue) {
      const html = `<a href="http://www.google.com/maps/place/${store.latitude},${store.longitude}" target="_blank">${store.name}</a>`;
      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(html);
      this.marker << new mapboxgl.Marker().setLngLat([store.longitude, store.latitude]).setPopup(popup).addTo(this.map);
    }
  }
}
