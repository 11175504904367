// Rails
require('@rails/ujs').start()
import { Turbo } from '@hotwired/turbo-rails'
import 'flatpickr/dist/flatpickr'
import 'flatpickr/dist/l10n/fr'
import 'mapbox-gl/dist/mapbox-gl'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/browser'

// Stimulus
import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'


// Own code
import '../channels'
import '../shared/images'
import '../controllers'

// Styles
import './application.scss'

require('@rails/activestorage').start()

// Plugins
require('typeface-inter')

window.App || (window.App = {})

document.addEventListener('turbo:load', () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'pageview', pagePath: window.location.pathname,
    pageUrl: window.location.href, pageTitle: document.title
  })

  window.gdprAppliesGlobally = true;
  (function () {
    function a(e) {
      if (!window.frames[e]) {
        if (document.body && document.body.firstChild) {
          var t = document.body
          var n = document.createElement('iframe')
          n.style.display = 'none'
          n.name = e
          n.title = e
          t.insertBefore(n, t.firstChild)
        } else {
          setTimeout(function () {
            a(e)
          }, 5)
        }
      }
    }

    function e(n, r, o, c, s) {
      function e(e, t, n, a) {
        if (typeof n !== 'function') {
          return
        }
        if (!window[r]) {
          window[r] = []
        }
        var i = false
        if (s) {
          i = s(e, t, n)
        }
        if (!i) {
          window[r].push({ command: e, parameter: t, callback: n, version: a })
        }
      }

      e.stub = true

      function t(a) {
        if (!window[n] || window[n].stub !== true) {
          return
        }
        if (!a.data) {
          return
        }
        var i = typeof a.data === 'string'
        var e
        try {
          e = i ? JSON.parse(a.data) : a.data
        } catch (t) {
          return
        }
        if (e[o]) {
          var r = e[o]
          window[n](
            r.command,
            r.parameter,
            function (e, t) {
              var n = {}
              n[c] = { returnValue: e, success: t, callId: r.callId }
              a.source.postMessage(i ? JSON.stringify(n) : n, '*')
            },
            r.version
          )
        }
      }

      if (typeof window[n] !== 'function') {
        window[n] = e
        if (window.addEventListener) {
          window.addEventListener('message', t, false)
        } else {
          window.attachEvent('onmessage', t)
        }
      }
    }

    e('__tcfapi', '__tcfapiBuffer', '__tcfapiCall', '__tcfapiReturn')
    a('__tcfapiLocator');
    (function (e) {
      var t = document.createElement('script')
      t.id = 'spcloader'
      t.type = 'text/javascript'
      t.async = true
      t.src = 'https://sdk.privacy-center.org/' + e + '/loader.js?target=' + document.location.hostname
      t.charset = 'utf-8'
      var n = document.getElementsByTagName('script')[0]
      n.parentNode.insertBefore(t, n)
    })('31e0c5bf-2cdb-41b4-abd9-b41218e5d5e4')
  })()
})

Sentry.init({
  dsn: window.App.sentryDSN,
  release: window.App.release,
  environment: window.App.env,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  beforeSend(event) { return null },
});

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true)
const ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(componentRequireContext)

Turbo.start()
window.Turbolinks = Turbo
ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount)
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount)
delete window.Turbolinks


const application = Application.start();
application.debug = App.env !== 'production'
const context = require.context('controllers', true, /^\.\/[^\/]+_controller\.js$/);
application.load(definitionsFromContext(context));
window.Stimulus = application

