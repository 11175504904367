import { Controller } from '@hotwired/stimulus'
import ahoy from 'ahoy.js'
import * as Sentry from '@sentry/browser'

export default class extends Controller {
  static targets = []
  static values = {
    autoTrackName: String,
    autoTrackParams: Object,
    autoTrackType: String,
    trackName: String,
    trackParams: Object,
    trackType: String,
    visibilityTrackName: String,
    visibilityTrackParams: Object,
    visibilityTrackType: String,
    debug: Boolean
  }

  initialize() {
    this.enabled = true
    this.debug = this.hasDebugValue || ['development', 'staging'].includes(window.App.env)
    this.wasInViewPort = false
    window.dataLayer = window.dataLayer || []
    this.isAutoTrackingVisibility = false
  }

  connect() { 
    document.addEventListener('DOMContentLoaded', () => {
      if (!this.enabled || document.documentElement.hasAttribute("data-turbolinks-preview")) return
      console.log('enabled')
  
      if (this.hasAutoTrackTypeValue) {
        this.autoTracking()
      }
  
      if (this.hasVisibilityTrackTypeValue) {
        this.autoTrackVisibility()
        addEventListener('scroll', () => {
          if(!this.isAutoTrackingVisibility) this.autoTrackVisibility.bind(this) 
        }, false)
      }
    }, false)
  }

  disconnect() {
    if (!this.enabled) return
    removeEventListener('scroll', this.autoTrackVisibility)
  }

  log(method, type, params) {
    if (!this.debug) return

    console.log(method)
    console.group()
    console.log('Type:', type)
    console.group()
    console.log('Params:')
    console.dir(params)
    console.groupEnd()
    console.groupEnd()
  }

  elementInViewPort() {
    const rect = this.element.getBoundingClientRect()

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  autoTracking() {
    this.log('autoTracking', { name: this.autoTrackNameValue, type: this.autoTrackTypeValue }, this.autoTrackParamsValue)

    switch (this.autoTrackNameValue) {
      case 'view_item_list':
        this.viewItemList(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break

      case 'view_item':
        this.viewItem(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break

      case 'view_cart':
        this.viewCart(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break

      case 'begin_checkout':
        this.beginCheckout(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break

      case 'add_shipping_info':
        this.addShippingInfo(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break

      case 'add_payment_info':
        this.addPaymentInfo(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break

      case 'purchase':
        this.purchase(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break
    }
  }

  autoTrackVisibility() {
    if (!this.elementInViewPort()) return
    if (this.wasInViewPort) return
    this.isAutoTrackingVisibility = true

    this.log('autoTrackVisibility', { name: this.visibilityTrackNameValue, type: this.visibilityTrackTypeValue }, this.visibilityTrackParamsValue)

    // Only track impression once
    this.wasInViewPort = true
    this.isAutoTrackingVisibility = false
    // this.visibilityTrackNameValue this.visibilityTrackTypeValue this.visibilityTrackParamsValue
  }

  autoTracking() {
    this.log('autoTracking', { name: this.autoTrackNameValue, type: this.autoTrackTypeValue }, this.autoTrackParamsValue)

    switch (this.autoTrackNameValue) {
      case 'view_item_list':
        this.viewItemList(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break

      case 'view_item':
        this.viewItem(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break

      case 'view_cart':
        this.viewCart(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break

      case 'begin_checkout':
        this.beginCheckout(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break

      case 'add_shipping_info':
        this.addShippingInfo(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break

      case 'add_payment_info':
        this.addPaymentInfo(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break

      case 'purchase':
        this.purchase(this.autoTrackTypeValue, this.autoTrackParamsValue)
        break
    }

    this.track(this.autoTrackNameValue, this.autoTrackTypeValue, this.autoTrackParamsValue)
  }

  viewItemList(type, params) {
    this.log('viewItemList', type, params)

    switch (type) {
      case 'product':
        this.viewProductList(params)
        break
    }
  }

  selectItem() {
    this.log('selectItem', this.trackTypeValue, this.trackParamsValue)

    switch (this.trackTypeValue) {
      case 'product':
        this.selectProduct(this.trackParamsValue)
        break
    }

    this.track(this.trackNameValue, this.trackTypeValue, this.trackParamsValue)
  }

  viewItem(type, params) {
    this.log('viewItem', type, params)

    switch (type) {
      case 'product':
        this.viewProduct(params)
        break
    }
  }

  viewCart(type, params) {
    this.log('viewCart', type, params)

    switch (type) {
      case 'products':
        this.viewProductCart(params)
        break
    }
  }

  addToCart() {
    this.log('addToCart', this.trackTypeValue, this.trackParamsValue)

    switch (this.trackTypeValue) {
      case 'product':
        this.addProductToCart(this.trackParamsValue)
        break
    }

    this.track(this.trackNameValue, this.trackTypeValue, this.trackParamsValue)
  }

  removeFromCart() {
    this.log('removeFromCart', this.trackTypeValue, this.trackParamsValue)

    switch (this.trackTypeValue) {
      case 'product':
        this.removeProductFromCart(this.trackParamsValue)
        break
    }

    this.track(this.trackNameValue, this.trackTypeValue, this.trackParamsValue)
  }

  beginCheckout(type, params) {
    this.log('beginCheckout', type, params)

    switch (type) {
      case 'products':
        this.beginProductCheckout(params)
        break
    }
  }

  addShippingInfo(type, params) {
    this.log('addShippingInfo', type, params)

    switch (type) {
      case 'products':
        this.addProductShippingInfo(params)
        break
    }
  }

  addPaymentInfo(type, params) {
    this.log('addPaymentInfo', type, params)

    switch (type) {
      case 'products':
        this.addProductPaymentInfo(params)
        break
    }
  }

  purchase(type, params) {
    this.log('purchase', type, params)
    Sentry.captureMessage('Purchase', { extra: { params: params, type: type } })
    switch (type) {
      case 'products':
        this.productPurchase(params)
        break
    }
  }

  // Generic tracking
  track(name, type, params) {
    if (!this.enabled) return

    ahoy.track(name, { ...params, ahoy_type: this.trackTypeValue })
  }

  // Generic tracking with Google
  trackWithGoogle(name, params) {
    if (!this.enabled) return

    window.dataLayer.push({
      event: name,
      ...params
    })
  }

  // Clear the previous ecommerce object with Google
  clearTrackingWithGoogle() {
    if (!this.enabled) return

    dataLayer.push({ ecommerce: null })
  }

  viewProductList(params) {
    this.log('viewProductList', null, params)
    this.clearTrackingWithGoogle()
    this.trackWithGoogle('view_item_list', {
      event: 'view_item_list',
      ecommerce: {
        items: params.items.map((item) => {
          return {
            item_name: item.name,
            item_id: item.id,
            price: item.price,
            index: item.position,
            item_brand: item.brand,
            item_category: item.category,
            item_list_id: item.list_id,
            item_list_name: item.list_name
          }
        })
      }
    })
  }

  selectProduct(params) {
    this.log('selectProduct', null, params)
    this.clearTrackingWithGoogle()
    this.trackWithGoogle('select_item', {
      ecommerce: {
        items: [
          {
            name: params.name,
            id: params.id,
            category: params.category,
            brand: params.brand,
            price: params.price,
            index: params.position,
            item_list_id: params.list_id,
            item_list_name: params.list_name
          }
        ]
      }
    })
  }

  viewProduct(params) {
    this.log('viewProduct', null, params)
    this.clearTrackingWithGoogle()
    this.trackWithGoogle('view_item', {
      ecommerce: {
        currency: 'EUR',
        value: params.price,
        items: [
          {
            item_name: params.name,
            item_id: params.id,
            item_category: params.category,
            item_brand: params.brand,
            price: params.price,
            item_list_id: params.list_id,
            item_list_name: params.list_name
          }
        ]
      }
    })
  }

  viewProductCart(params) {
    this.log('viewProductCart', null, params)
    this.clearTrackingWithGoogle()
    this.trackWithGoogle('view_cart', {
      ecommerce: {
        currency: 'EUR',
        value: params.final_price,
        items: params.items.map((item) => {
          return {
            item_name: item.name,
            item_id: item.id,
            price: item.price,
            item_brand: item.brand,
            item_category: item.category,
            quantity: item.quantity,
            item_list_id: item.list_id,
            item_list_name: item.list_name
          }
        })
      }
    })
  }

  addProductToCart(params) {
    this.log('addProductToCart', null, params)
    this.clearTrackingWithGoogle()
    this.trackWithGoogle('add_to_cart', {
      ecommerce: {
        currency: 'EUR',
        value: params.total_price,
        items: [
          {
            item_name: params.name,
            item_id: params.id,
            item_category: params.category,
            item_brand: params.brand,
            quantity: params.quantity,
            price: params.price,
            item_list_id: params.list_id,
            item_list_name: params.list_name
          }
        ]
      }
    })
  }

  removeProductFromCart(params) {
    this.log('removeProductFromCart', null, params)
    this.clearTrackingWithGoogle()
    this.trackWithGoogle('remove_from_cart', {
      ecommerce: {
        currency: 'EUR',
        value: params.price,
        items: [
          {
            item_name: params.name,
            item_id: params.id,
            price: params.price,
            item_brand: params.brand,
            item_category: params.category,
            quantity: params.quantity,
            item_list_id: params.list_id,
            item_list_name: params.list_name
          }
        ]
      }
    })
  }

  beginProductCheckout(params) {
    this.log('beginProductCheckout', null, params)
    this.clearTrackingWithGoogle()
    this.trackWithGoogle('begin_checkout', {
      ecommerce: {
        currency: 'EUR',
        value: params.final_price,
        coupon: params.promo_code,
        items: params.items.map((item) => {
          return {
            item_name: item.name,
            item_id: item.id,
            item_category: item.category,
            item_brand: item.brand,
            quantity: item.quantity,
            price: item.price,
            item_list_id: item.list_id,
            item_list_name: item.list_name
          }
        })
      }
    })
  }

  addProductShippingInfo(params) {
    this.log('addProductShippingInfo', null, params)
    this.clearTrackingWithGoogle()
    this.trackWithGoogle('add_shipping_info', {
      ecommerce: {
        currency: 'EUR',
        value: params.final_price,
        coupon: params.promo_code,
        shipping_tier: params.shipping_method === 'mail_shipping' ? 'Standard' : (params.shipping_method === 'email_shipping' ? 'eCard' : 'Mixed'),
        items: params.items.map((item) => {
          return {
            item_name: item.name,
            item_id: item.id,
            item_category: item.category,
            item_brand: item.brand,
            quantity: item.quantity,
            price: item.price,
            item_list_id: item.list_id,
            item_list_name: item.list_name
          }
        })
      }
    })
  }

  addProductPaymentInfo(params) {
    this.log('addProductPaymentInfo', null, params)
    this.clearTrackingWithGoogle()
    this.trackWithGoogle('add_payment_info', {
      ecommerce: {
        currency: 'EUR',
        value: params.final_price,
        coupon: params.promo_code,
        payment_type: 'Credit Card',
        items: params.items.map((item) => {
          return {
            item_name: item.name,
            item_id: item.id,
            item_category: item.category,
            item_brand: item.brand,
            quantity: item.quantity,
            price: item.price,
            item_list_id: item.list_id,
            item_list_name: item.list_name
          }
        })
      }
    })
  }

  productPurchase(params) {
    this.log('productPurchase', null, params)
    this.clearTrackingWithGoogle()
    this.trackWithGoogle('purchase', {
      ecommerce: {
        currency: 'EUR',
        tax: params.tax,
        shipping: params.shipping_price,
        value: params.final_price,
        transaction_id: params.id,
        coupon: params.promo_code,
        items: params.items.map((item) => {
          return {
            item_name: item.name,
            item_id: item.id,
            price: item.price,
            item_brand: item.brand,
            item_category: item.category,
            quantity: item.quantity,
            item_list_id: item.list_id,
            item_list_name: item.list_name
          }
        })
      }
    })
  }
}
