import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [];

  zoom(event) {
    event.preventDefault();

    for (const fullImage of this.element.querySelectorAll(".images-zoomer-full-image")) {
      fullImage.classList.add("hidden");
    }

    this.element.querySelector(`#${event.target.getAttribute("data-image-id")}`).classList.remove("hidden");
  }
}
