import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['searchInput', 'address', 'zipcode', 'city', 'country'];

  connect() {
    let options = {
      types: ['geocode'],
      componentRestrictions: { country: 'fr' },
    };

    this.autocomplete = new google.maps.places.Autocomplete(this.searchInputTarget, options);
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
  }

  disconnect() {
    this.autocomplete.removeEventListener('place_changed', this.placeChanged.bind(this))
  }

  placeChanged() {
    const place = this.autocomplete.getPlace();
    let address = '';

    for (let component of place.address_components) {
      if (component.types.includes('street_number')) {
        address = `${component.long_name} ${address}`;
      } else if (component.types.includes('route')) {
        address = `${address} ${component.long_name}`;
      } else if (component.types.includes('locality')) {
        this.cityTarget.value = component.long_name;
      } else if (component.types.includes('postal_code')) {
        this.zipcodeTarget.value = component.long_name;
      } else if (component.types.includes('country')) {
        this.countryTarget.value = component.short_name;

        for (let option, j = 0; (option = this.countryTarget.options[j]); j++) {
          if (option.value !== component.short_name) continue

          this.countryTarget.selectedIndex = j
          break
        }
      }
    }

    this.addressTarget.value = address;
  }
}
