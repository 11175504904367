import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content", "chevronDown", "chevronUp"];

  toggle() {
    this.contentTarget.classList.toggle("hidden");

    if (this.hasChevronDownTarget) {
      this.chevronDownTarget.classList.toggle("hidden");
    }

    if (this.hasChevronUpTarget) {
      this.chevronUpTarget.classList.toggle("hidden");
    }
  }
}
