import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["longText", "showMoreButton"];

  initialize() {
    this.currentTab = null;
  }

  switch(event) {
    event.preventDefault();

    let tabName = event.target.getAttribute("data-target-tab-name");
    let tab = document.querySelector(`div[data-tab-name="${tabName}"]`);

    if (tab !== null) {
      if (this.currentTab !== null) {
        document.querySelector(`div[data-tab-name="${this.currentTab}"]`).classList.toggle("hidden");
      }

      tab.classList.toggle("hidden");

      this.currentTab = tabName;
    }
  }
}
