import { Controller } from '@hotwired/stimulus'
import autoComplete from '@tarekraafat/autocomplete.js'
import { visit } from '../components/utils'

export default class extends Controller {
  static targets = ['form', 'search']
  static values = { placeholder: String }

  connect() {
    this.id = `autocomplete-${this.uuidv4()}`
    this.searchTarget.setAttribute('id', this.id)

    const autoCompleteJS = new autoComplete({
      data: {
        src: async (query) => {
          try {
            const source = await fetch(`${this.data.get('url')}?q=${query}`)
            const data = await source.json()
            return data
          } catch (error) {
            return error
          }
        },
        key: ['name'],
        cache: false
      },
      selector: `#${this.id}`,
      placeHolder: this.placeholderValue,
      threshold: 2,
      debounce: 200,
      diacritics: true,
      searchEngine: (query, record) => record,
      resultItem: {
        element: (item, data) => {
          item.innerHTML = `<span>${data.match.name}</span>`
        },
        highlight: true
      },
      resultsList: {
        element: (list, data) => {
          if (App.env !== 'development') return
          if (!data.results.length) {
            const message = document.createElement('li')
            message.setAttribute('class', 'autoComplete_no_result')
            message.innerHTML = `<span>Found No Results for "${data.query}"</span>`
            list.prepend(message)
          }
        },
        noResults: App.env === 'development',
        maxResults: 15
      },
      events: {
        input: {
          focus() {
            if (autoCompleteJS.input.value.length) autoCompleteJS.start()
          },
          selection(event) {
            const feedback = event.detail
            autoCompleteJS.input.blur()
            if ((typeof feedback.selection.value.public_url) !== 'undefined') {
              visit(feedback.selection.value.public_url)
            } else {
              this.searchTarget.value = feedback.selection.value.name
              this.formTarget.submit()
            }
          }
        }
      }
    })
  }

  clear(event) {
    event.preventDefault()

    this.searchTarget.value = ''
    this.searchTarget.focus()
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }
}
