import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["button", "handle", "rail", "input"];
  static values = {
    autoSubmit: Boolean,
  };

  connect() {
    this.toggle(null, false);
  }

  toggle(event, updateInput = true) {
    if (updateInput) {
      this.inputTarget.value = this._checked() ? '0' : '1'

      if (this.hasAutoSubmitValue && this.autoSubmitValue) {
        Rails.fire(this.element.querySelector('form'), 'submit')
      }
    }

    if (this._checked()) {
      this.buttonTarget.style = 'background-color: #0678C0';
      this.railTarget.style = 'background-color: #0678C0';
      this.handleTarget.style = 'transform: translateX(1.25rem)';
    } else {
      this.buttonTarget.style = 'background-color: transparent';
      this.railTarget.style = 'background-color: #e5e7eb';
      this.handleTarget.style = 'transform: translateX(0)';
    }
  }

  _checked() {
    return this.inputTarget.value === '1' || this.inputTarget.value === 'true'
  }
}
