import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs";
import debounce from "lodash.debounce";

export default class extends Controller {
  static targets = ["form"];
  static values = {
    url: String,
  };

  initialize() {
    this.autosave = debounce(this.autosave, 250).bind(this);
  }

  submit() {
    // TODO : Find another way to fire the event
    Rails.fire(this.formTarget, "submit");
  }

  select(event) {
    let input = event.target.querySelector('input')
    input ||= event.target.closest('div').querySelector('input')
    input.checked = true
    this.submit()
  }

  autosave() {
    const formdata = new FormData(this.formTarget);
    const url = this.hasUrlValue ? this.urlValue : this.formTarget.getAttribute("action") + ".json";

    fetch(url, {
      method: "PUT",
      credentials: "include",
      headers: {
        "X-CSRF-Token": Rails.csrfToken(),
      },
      body: formdata,
    });
  }
}
