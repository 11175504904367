import { Controller } from "@hotwired/stimulus"
import Zoom from "smooth-zoom";

export default class extends Controller {
  static targets = [];

  connect() {
    Zoom("img");
  }
}
