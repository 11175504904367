import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js/public/assets/scripts/choices";

export default class extends Controller {
  static targets = [];

  connect() {
    this.choices = new Choices(this.element.querySelector("select"), {
      itemSelectText: "",
      noResultsText: "Aucun résultat",
      noChoicesText: "Aucun choix à afficher",
      itemSelectText: "Pressez pour selectionner",
    });
  }

  disconnect() {
    this.choices.destroy();
  }
}
