import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'mobileMenu', 'searchBar',
    'productsMenu', 'conceptMenu', 'showsMenu', 'venuesMenu',
    'mobileMenuProducts', 'mobileMenuShows', 'mobileMenuVenues',
  ]

  openProductsMenu() {
    this.remove_hidden_class(this.productsMenuTarget)
  }

  closeProductsMenu() {
    this.add_hidden_class(this.productsMenuTarget)
  }

  openConceptMenu() {
    this.remove_hidden_class(this.conceptMenuTarget)
  }

  closeConceptMenu() {
    this.add_hidden_class(this.conceptMenuTarget)
  }

  openShowsMenu() {
    this.remove_hidden_class(this.showsMenuTarget)
  }

  closeShowsMenu() {
    this.add_hidden_class(this.showsMenuTarget)
  }

  openVenuesMenu() {
    this.remove_hidden_class(this.venuesMenuTarget)
  }

  closeVenuesMenu() {
    this.add_hidden_class(this.venuesMenuTarget)
  }

  toggleMobileMenu() {
    this.toggle_hidden_class(this.mobileMenuTarget)
    this.toggle_hidden_class(this.searchBarTarget)
  }

  toggleMobileMenuProducts(event) {
    event.preventDefault()
    this.toggle_hidden_class(this.mobileMenuProductsTarget)
  }

  toggleMobileMenuShows(event) {
    event.preventDefault()
    this.toggle_hidden_class(this.mobileMenuShowsTarget)
  }

  toggleMobileMenuVenues(event) {
    event.preventDefault()
    this.toggle_hidden_class(this.mobileMenuVenuesTarget)
  }

  closeAllMenus() {
    this.add_hidden_class(this.productsMenuTarget)
    this.add_hidden_class(this.conceptMenuTarget)
    this.add_hidden_class(this.showsMenuTarget)
    this.add_hidden_class(this.venuesMenuTarget)
    this.remove_hidden_class(this.searchBarTarget)
  }

  toggle_hidden_class(target) {
    target.classList.toggle('hidden');
  }

  add_hidden_class(target) {
    target.classList.add('hidden');
  }

  remove_hidden_class(target) {
    target.classList.remove('hidden');
  }
}
