import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr/dist/flatpickr';
import 'flatpickr/dist/l10n/fr';
import { i18n } from 'i18n/index.js';

export default class extends Controller {
  connect() {
    flatpickr(this.element, {
      locale: i18n.locale,
      altFormat: 'd-m-Y H:i',
      altInput: true,
      dateFormat: 'Y-m-d H:i',
      enableTime: true,
      time_24hr: true,
    });
  }
}
