import { Controller } from "@hotwired/stimulus"
import mapboxgl from "mapbox-gl";

export default class extends Controller {
  static targets = [];
  static values = { popup: String, geolocation: Boolean, latitude: Number, longitude: Number, token: String };

  initialize() {
    mapboxgl.accessToken = this.tokenValue;
    this.element.style.height = "250px";
  }

  connect() {
    this.map = new mapboxgl.Map({
      container: this.element.id,
      interactive: true,
      zoom: 12,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [this.longitudeValue, this.latitudeValue],
    });

    this.map.addControl(new mapboxgl.NavigationControl());

    if (this.hasGeolocationValue && this.geolocationValue) {
      this.map.addControl(
        new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: false,
        })
      );
    }

    const html = `<a href="http://www.google.com/maps/place/${this.latitudeValue},${this.longitudeValue}" target="_blank">${this.popupValue}</a>`;
    const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(html);
    this.marker = new mapboxgl.Marker()
      .setLngLat([this.longitudeValue, this.latitudeValue])
      .setPopup(popup)
      .addTo(this.map);
  }
}
