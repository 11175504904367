import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation, Autoplay } from "swiper";
import 'swiper/css';

export default class extends Controller {
  static targets = [];

  initialize() {
    Swiper.use([Navigation, Autoplay]);
  }

  connect() {
    this.swiper = new Swiper(".swiper", {
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      slidesPerView: 1,
      slidesPerGroup: 1,
      loop: true,
      spaceBetween: 20,
      // navigation: {
      //   nextEl: ".swiper-button-next",
      //   prevEl: ".swiper-button-prev",
      // },
      navigation: false,
      breakpoints: {
        640: {
          slidesPerView: 1,
          slidesPerGroup: 1,
          spaceBetween: 50,
        },
        768: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 5,
          slidesPerGroup: 5,
          spaceBetween: 20,
        },
      },
    });
  }

  toggleShowDates(event) {
    const target = event.target;

    if (target.querySelector(".show-dates") !== null) {
      target.querySelector(".show-dates").classList.toggle("hidden");
    }
  }
}
