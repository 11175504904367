import { Controller } from '@hotwired/stimulus'
import Cleave from 'cleave.js';
import 'cleave.js/dist/addons/cleave-phone.fr';

export default class extends Controller {
  static targets = [];

  connect() {
    const maskType = this.element.getAttribute('data-type');

    switch (maskType) {
      case 'date':
        this.dateMask();
        break;
      case 'phone':
        this.phoneMask();
        break;
    }
  }

  dateMask() {
    new Cleave(this.element, {
      date: true,
      delimiter: '-',
      datePattern: ['d', 'm', 'Y'],
    });
  }

  phoneMask() {
    new Cleave(this.element, {
      phone: true,
      phoneRegionCode: 'fr',
    });
  }
}
