import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";

export default class extends Controller {
  static targets = ["occurrence"];

  connect() {
    for (const occurrence of this.occurrenceTargets) {
      tippy(occurrence, {
        content: occurrence.dataset.tooltip,
      });
    }
  }

  toggleOccurrences(event) {
    for (const occurrence of document.querySelectorAll(".calendar-occurrences")) {
      occurrence.classList.add("hidden");
    }

    const date = event.target.dataset.date;

    for (const occurrence of document.querySelectorAll(`.calendar-occurrences-${date}`)) {
      occurrence.classList.remove("hidden");
    }
  }
}
